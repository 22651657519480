import React from "react"
import { FlexCol } from "./style"

// * NUMBER OF COLUMNS (12 COLUMN DESIGN SYSTEM)
//xs : mobile
//sm : tablet, 768px
//md : desktop, 1024px
//lg : large, 1280px

// * COLUMNS AT CUSTOM BREAKPOINTS
// c1 : [bp, #cols]
// c2 : [bp, #cols]

// * ALIGMENT OF ITEMS WITHIN COL
// xsAlignX : "start" , "end" , "center"
// smAlignX
// mdAlignX
// lgAlignX
// xsAlignY
// smAlignY
// mdAlignY
// lgAlignY

const Col = (props) => {
  return <FlexCol {...props} className={`${props.className} col`} />
}

export default Col
