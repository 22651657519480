import React from "react"
import PropTypes from "prop-types"
//Documentation
//https://www.npmjs.com/package/react-styled-flexboxgrid
//https://loicmahieu.github.io/react-styled-flexboxgrid/demo/index.html

// gap
// rowGap => {[ up to 4 b.p ]}

import { FlexRow } from "./style"
const Row = (props) => {
  return (
    <FlexRow
      {...props}
      className={`${props.className} row`}
      xsGap={props.xsGap === 0 ? "0" : props.xsGap}
      smGap={props.smGap === 0 ? "0" : props.smGap}
      mdGap={props.mdGap === 0 ? "0" : props.mdGap}
      lgGap={props.lgGap === 0 ? "0" : props.lgGap}
    />
  )
}

export default Row

Row.propTypes = {
  gap: PropTypes.array,
  rowGap: PropTypes.array,
}
